<template>
  <div class="service-player-controls">
    <slot />

    <div class="spacer" />

    <transition-group
      name="fade-slowly"
      tag="div"
      class="d-flex-r d-flex-a-c h-spacing-3"
    >
      <template v-for="(control, key) in controls">
        <SelectableButton
          v-if="control.display"
          :key="key"
          :value="control.value"
          outlined
          @input="onInput($event, key)"
        >
          {{ control.icon }}
        </SelectableButton>
      </template>

      <ServicePlayerDownloadButton
        key="download"
        :service="service"
        :date-range="dateRange"
        :state="state"
        :media="media"
      />
    </transition-group>
  </div>
</template>

<script>
// components
import SelectableButton from '@/components/ds/SelectableButton.vue';
import ServicePlayerDownloadButton from './buttons/ServicePlayerDownload.vue';

export default {
  name: 'ServicePlayerControls',
  components: { SelectableButton, ServicePlayerDownloadButton },
  props: {
    controls: { type: Object, required: true },

    service: { type: Object, required: true },
    dateRange: { type: Object, required: true },

    state: { type: Object, default: undefined },
    media: { type: String, default: undefined },
  },
  methods: {
    onInput(e, key) {
      this.$emit('input', e, key);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.service-player-controls
  +d-flex-r(center)
  +v-padding($spacing-3)

+media-down(md)
  .service-player-controls
    +h-padding($spacing-2)

    & > .e-text
      font-size: $f-size-sm

+media-up(md)
  .service-player-controls
    margin-bottom: $spacing-3

    border-radius: 4px // FIX
    box-shadow: $elevation-1

    +h-padding($spacing-4)

    & > .e-text
      font-size: $f-size-md

// color
+media-up(md)
  .service-player-controls
    background-color: var(--c-background)

    & > .e-icon, & > .e-text
      --color: var(--c-secondary)

+media-down(md)
  .service-player-controls
    & > .e-icon, & > .e-text
      --color: var(--c-gray-2)
</style>
