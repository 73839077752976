<template>
  <div class="player__overlay">
    <slot name="thumbnail" />

    <icon v-if="displayBigPlay" color="white">play</icon>
  </div>
</template>

<script>
export default {
  name: 'PlayerOverlay',
  props: {
    displayBigPlay: { type: Boolean, required: true },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import lib.sass

// base
$z-index: $overlay-z-index

.player__overlay
  +d-flex-r(center, center)
  +p-absolute($z-index, 0)

.player__overlay > .e-icon
  z-index: #{$z-index + 1}

  +size($event-size)
</style>
