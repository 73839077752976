import API from '@/API';
import preprocessor from '@/API/preprocessor/index.ts';
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';
import { fetchFile, time } from '@/helpers';

const QUALITY = '720p';

type Quality = '1080p' | '720p' | '480p' | '360p';

interface File {
  bucket: string;
  filename: string;
  uri?: string;
}

interface ServiceDefaultProcess {
  frames: { [key: string]: RemoteTimestamp };
  video_info: Record<Quality, { framerate: number }>;
}

interface ServiceHeatmapProcess {
  periods: {
    [key: string]: { bucket: string; heatmap?: string; filename?: string };
  };
}

export class ServicePlayerBridge {
  serviceId: Id;
  dateRange: DateRange;

  days: {
    vg: { timestamps: Timestamp[]; frameRate: number };
    h: { [key: string]: Promise<File | undefined> };
  }[];

  constructor(serviceId: Id, dateRange: DateRange) {
    this.serviceId = serviceId;
    this.dateRange = dateRange;

    this.days = [];
  }

  mapVideoGenerationResponse(response: ServiceDefaultProcess) {
    return {
      timestamps: _.map(response.frames, preprocessor.timestamp),
      frameRate: response['video_info'][QUALITY].framerate,
    };
  }

  mapHeatmapResponse(response: ServiceHeatmapProcess) {
    return _.mapValues(response.periods, async period => {
      const filename = period.heatmap || period.filename;

      if (filename)
        return <File>await fetchFile({ bucket: period.bucket, filename });
    });
  }

  async fetch() {
    const has = { vg: false, h: false };

    const remoteDateRange = <RemoteDateRange>(
      time.getDateRangeAsRemoteDateRange(this.dateRange)
    );
    const days = time.getRemoteDateRangeDays(<[any, any]>remoteDateRange);

    // { [key: RemoteDate]: { vg, h } }
    const days2 = _(days)
      .keyBy()
      .mapValues(async day => {
        const vgResponse = <ServiceDefaultProcess>(
          await API.processes(this.serviceId, day, PROCESSES.default)
        );

        if (vgResponse) {
          const vg = this.mapVideoGenerationResponse(vgResponse);

          const hResponse = <ServiceHeatmapProcess>(
            await API.processes(this.serviceId, day, PROCESSES.heatmap)
          );

          if (hResponse) {
            const h = this.mapHeatmapResponse(hResponse);

            return { vg, h };
          } else return { vg };
        }
      })
      .value();

    // Array<{ vg, h }>
    const days3 = _.compact(
      await Promise.all(
        _.map(days2, async day => {
          const solvedDay = await day;
          if (!solvedDay) return;
          const { vg, h } = solvedDay;

          if (vg) {
            has.vg = true;

            if (h) {
              has.h = true;

              return { vg, h };
            }
          }
        })
      )
    );

    return { days: days3, has };
  }

  async setup() {
    const response = await this.fetch();

    this.days = response.days;

    return true;
  }

  async getHeatmapByTime(time_: number) {
    for (const day of Object.values(this.days)) {
      const { vg } = day;
      const duration = vg.timestamps.length / vg.frameRate;

      if (time_ <= duration) {
        const timestamp = vg.timestamps[Math.floor(time_ * vg.frameRate)];
        const key = time.format.asKey(timestamp);

        return { heatmap: await day.h[key], key };
      }

      time_ -= duration;
    }
  }
}

export default ServicePlayerBridge;
