<template>
  <div v-show="buffering || !isEmpty" class="player__spinner">
    <Progress variant="circular" />

    <transition-group name="fade-y" tag="div" appear>
      <slot />
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'PlayerSpinner',
  props: {
    buffering: { type: Boolean, required: true },
  },
  data() {
    return { isEmpty: true };
  },
  created() {
    this.setIsEmpty();
  },
  updated() {
    this.setIsEmpty();
  },
  methods: {
    setIsEmpty() {
      const { default: defaultSlot } = this.$slots;

      this.isEmpty = defaultSlot ? defaultSlot.length === 0 : true;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import lib.sass

// base
$margin-top: $spacing-2
$progress-size: 32px
$z-index: $spinner-z-index

.player__spinner
  +p-relative($z-index)

  & > .progress-circular
    --size: #{$progress-size}

.player__spinner > div:last-child
  position: absolute
  top: calc(100% + #{$margin-top})

  transform: translateX(calc(-50% + #{$progress-size / 2}))

  +d-flex-c(center)
</style>
