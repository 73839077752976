<template>
  <Player
    ref="player"
    :uri="uri"
    v-bind="$attrs"
    @setup="onSetup"
    v-on="$listeners"
  >
    <template v-slot:body>
      <slot name="body" />
    </template>

    <template v-slot:thumbnail>
      <ServiceThumbnail :service="service" class="service-player__thumbnail" />
    </template>

    <template v-slot:center>
      <slot name="center" />
    </template>

    <template v-slot:bottom-right>
      <slot name="bottom-right" />
    </template>

    <template v-slot:spinner>
      <slot name="spinner" />
    </template>
  </Player>
</template>

<script>
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';
import { functions } from '@/helpers';

// components
import Player from '@/components/the-big-ones/Player/index.vue';
import ServiceThumbnail from '@/components/collection/services/ServiceThumbnail/index.vue';

export default {
  name: 'ServicePlayer',
  components: { Player, ServiceThumbnail },
  props: {
    service: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    process: { type: String, default: PROCESSES.default },
  },
  data() {
    return {
      uri: '',

      media: null,

      unwatch: null,
    };
  },
  computed: {
    remoteDateRange() {
      return this.$time.getDateRangeAsRemoteDateRange(this.dateRange);
    },

    ready() {
      return !_.isNull(this.media);
    },
  },
  created() {
    this.unwatch = this.$watch(
      () =>
        `${this.service.id}${this.remoteDateRange[0]}${this.remoteDateRange[1]}${this.media}${this.process}`,
      this.fetch
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    fetch_() {
      return functions.generateVideo({
        serviceId: this.service.id,
        dateRange: this.remoteDateRange,

        process: this.process,

        media: this.media,
      });
    },

    async fetch() {
      if (!this.ready) return;

      this.$emit('fetching-uri', true);

      let { player } = this.$refs;
      if (player) player.state.player.pause();

      const response = await this.fetch_();
      if (!response || !response.uri) return; // FIX: handle
      const { uri } = response;
      if (!uri) return; // FIX: handle

      this.uri = uri;

      this.$emit('fetching-uri', false);
    },

    onSetup({ media }) {
      this.media = media;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$thumbnail-blur: 10px

.service-player__thumbnail
  filter: blur($thumbnail-blur)

  +p-absolute($z-index-base)
  +size(calc(100% + #{$thumbnail-blur * 2}))
</style>
