import hotKeys from './hotKeys';

const EVENT = 'keydown';

export class PlayerHotKeys {
  handler;

  constructor(state) {
    this.handler = e => {
      const { key } = e;
      const hotkey = _.find(hotKeys, hotkey =>
        _.isSet(hotkey.key) ? hotkey.key.has(key) : hotkey.key === key
      );
      if (!hotkey) return;

      e.preventDefault();
      hotkey.handler({ state, event: e, key });
    };

    document.addEventListener(EVENT, this.handler);
  }

  teardown() {
    document.removeEventListener(EVENT, this.handler);
  }
}

export default PlayerHotKeys;
