<template>
  <div :class="className">
    <input
      type="range"
      :style="inputStyle_"
      class="player-range__input"
      v-bind="inputBind"
      @mouseenter="onHoverStart"
      @mouseleave="onHoverEnd"
      @mousemove="onHover"
      v-on="$listeners"
    />

    <div class="player-range__thumb" :style="thumbStyle_" />

    <div
      v-show="display.valueLabel"
      class="player-range__label"
      :style="style.valueLabel"
    >
      <slot name="value-label" />
    </div>

    <div
      v-show="display.nextValueLabel"
      class="player-range__label"
      :style="style.nextValueLabel"
    >
      <slot name="next-value-label" />
    </div>
  </div>
</template>

<script>
import RangeHovering from './RangeHovering';

const DEFAULT_INPUT_BIND = Object.freeze({ max: '1', min: '0', step: 'any' });

export default {
  name: 'PlayerRange',
  mixins: [RangeHovering],
  props: {
    value: { type: Number, default: 0 },

    inputStyle: { type: Object, default: undefined },
    thumbStyle: { type: Object, default: undefined },

    disabled: { type: Boolean, default: false },

    hideInput: { type: Boolean, default: false },
    hideThumb: { type: Boolean, default: false },
  },
  computed: {
    display() {
      return {
        valueLabel: true,
        nextValueLabel: this.hovering,
      };
    },
    style() {
      return {
        valueLabel: { left: `calc(${this.value * 100}%)` },
        nextValueLabel: { left: `calc(${this.nextValue * 100}%)` },
      };
    },

    inputStyle_() {
      const hiddenStyle = { opacity: 0 };

      return Object.assign({}, this.inputStyle, this.hideInput && hiddenStyle);
    },
    inputBind() {
      return {
        ...DEFAULT_INPUT_BIND,

        value: this.value,

        ...this.$attrs,
      };
    },

    thumbStyle_() {
      const hiddenStyle = { opacity: 0 };

      return Object.assign(
        { left: `calc(${this.value * 100}%)` },
        this.thumbStyle,
        this.hideThumb && hiddenStyle
      );
    },

    className() {
      const className = 'player-range';

      return [className, { [`${className}--disabled`]: this.disabled }];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$default-height: 32px
$height: var(--height, $default-height)

$default-thumb-color: var(--c-primary)
$default-thumb-size: 12px
$default-track-color: $c-transparent
$default-track-height: $height

.player-range
  position: relative

  width: 100%
  height: $height

.player-range__input
  width: 100%
  margin: 0

  background-color: transparent

  -webkit-appearance: none

  +p-absolute($z-index-base)

  &::-webkit-slider-runnable-track
    width: 100%
    height: var(--track-height, $default-track-height)

    background-color: var(--track-color, $default-track-color)

    cursor: pointer

    -webkit-appearance: none

  &::-webkit-slider-thumb
    width: 0 // it seems the browser needs this to be defined...

    -webkit-appearance: none

  &:focus
    outline: none

.player-range__thumb
  top: 50%
  left: 0

  background-color: var(--thumb-color, $default-thumb-color)
  border-radius: $b-radius-circle

  transform: translate(-50%, -50%)

  transition: transform $duration-quickly

  +p-absolute(#{$z-index-base + 1})
  +size(var(--thumb-size, $default-thumb-size))

.player-range__label
  top: 0

  transform: translate(-50%, -50%)

  pointer-events: none

  +p-absolute(#{$z-index-base + 2})

// - disabled
.player-range--disabled
  pointer-events: none
</style>
