<template>
  <EButton v-bind="bind" v-on="$listeners" @click="onClick">
    <slot />
  </EButton>
</template>

<script>
export default {
  name: 'SelectableButton',
  props: {
    value: { type: Boolean, default: false },
  },
  computed: {
    bind() {
      const bind = { icon: true, selected: this.value, md: true };

      return Object.assign(bind, this.$attrs);
    },
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
