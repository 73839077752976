<template>
  <Tooltip
    v-bind="$attrs"
    :class="['text-tooltip', { 'text-tooltip--ligth': ligth }]"
  >
    <t block xs><slot /></t>
  </Tooltip>
</template>

<script>
// components
import Template from './Template.vue';

export default {
  name: 'TextTooltip',
  components: { Tooltip: Template },
  props: {
    ligth: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$border-radius: $b-radius-1
$padding: $spacing-1 $spacing-2

.text-tooltip > .e-text
  padding: $padding

  border-radius: $border-radius

// color
.text-tooltip > .e-text
  --color: var(--c-white)

  background-color: var(--c-secondary)

// - ligth
.text-tooltip--ligth > .e-text
  --color: var(--c-secondary)

  background-color: var(--c-background)
</style>
