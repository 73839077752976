<template>
  <Option class="you-tube-menu__option--select">
    <t color="white" medium sm>{{ option.text }}</t>

    <div class="spacer" />

    <t v-if="activeOption" color="white" xs>{{ activeOption.text }}</t>
    <icon color="white" :size="20" class="ml-2">chevron-right</icon>
  </Option>
</template>

<script>
// components
import Option from './Option.vue';

export default {
  name: 'YouTubeMenuSelectOption',
  components: { Option },
  props: {
    option: { type: Object, required: true },
  },
  computed: {
    activeOption() {
      return _.find(this.option.options, { active: true });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
