<template>
  <div class="carousel">
    <div
      ref="body"
      v-hammer:pan.start="onTouchStart"
      v-hammer:panmove="onTouchMove"
      v-hammer:panend="onTouchEnd"
      class="carousel__body"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { getElWidth, parseTranslateX, tokens } from '@/helpers';

const DELTA_X_THRESHOLD = 16;
const VELOCITY_X_THRESHOLD = 0.1;

export default {
  name: 'Carousel',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
  },
  data() {
    return Object.assign(
      {
        animation: { instance: null },
      },
      this.getDefaultData()
    );
  },
  watch: {
    page() {
      this.shift_(this.velocityX);
    },
    pages(current, previous) {
      const shift = current - previous;
      if (shift >= 0) return;

      this.reset();
    },
  },
  methods: {
    getDefaultData() {
      return {
        deltaX: 0,
        startTranslateX: 0,
        velocityX: 0,

        isDragging: false,
      };
    },
    reset() {
      this.$emit('page-change', 0);

      this.$nextTick(() => {
        Object.assign(this.$data, this.getDefaultData());
        this.shift_();
      });
    },

    getBodyWidth() {
      const { body } = this.$refs;
      if (!body) return;

      return getElWidth(body);
    },
    getPageWidth() {
      const { body } = this.$refs;
      if (!body) return;

      // assumes all the slides have the same width
      const [firstSlide] = body.querySelectorAll('.slide');

      return getElWidth(firstSlide);
    },

    pauseAnimation() {
      const { animation } = this;
      if (animation.instance) animation.instance.pause();
    },
    shift_(velocityX = 0) {
      const { body } = this.$refs;
      const pw = this.getPageWidth();
      const translateX =
        -this.page * pw +
        Boolean(this.page === this.pages - 1) * (this.getBodyWidth() - pw);

      this.pauseAnimation();

      const { animation } = this;

      if (!velocityX)
        animation.instance = this.$a({
          targets: body,
          translateX: `${translateX}px`,
          easing: 'easeInOutQuad',
          duration: tokens.durations('normal'),
        });
      else
        animation.instance = this.$a({
          targets: body,
          translateX: `${translateX}px`,
          easing: `spring(1, 100, 100, ${velocityX})`,
        });
    },

    shift(pageShift, velocityX = 0) {
      const newPage = Math.min(
        Math.max(0, Math.floor(this.page + pageShift)),
        this.pages - 1
      );

      if (0 <= newPage && newPage < this.pages && newPage !== this.page) {
        this.velocityX = velocityX;
        this.$emit('page-change', newPage);
      } else this.shift_();
    },

    update() {
      requestAnimationFrame(this.update);

      if (this.isDragging) {
        const translateX = this.deltaX + this.startTranslateX;

        this.$refs.body.style.transform = `translateX(${translateX}px)`;
      }
    },

    onTouchStart(he) {
      this.deltaX = he.deltaX;
      this.startTranslateX = parseTranslateX(this.$refs.body.style.transform);

      this.isDragging = true;

      requestAnimationFrame(this.update);
    },
    onTouchMove(he) {
      this.deltaX = he.deltaX;
    },
    onTouchEnd(he) {
      const deltaX = Math.abs(this.deltaX);
      const direction = this.deltaX < 0 ? 1 : this.deltaX > 0 ? -1 : 0;
      const velocityX = Math.abs(he.velocityX);

      this.isDragging = false;

      if (deltaX < DELTA_X_THRESHOLD) this.shift_();
      else {
        if (velocityX < VELOCITY_X_THRESHOLD) {
          const pw = this.getPageWidth();
          const pageShift = direction * (1 + Math.floor(deltaX / pw));

          this.shift(pageShift);
        } else {
          const pageShift = direction * (1 + Math.floor(velocityX));

          this.shift(pageShift, velocityX);
        }
      }
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.carousel
  width: 100%

  overflow: hidden

.carousel__body
  flex-wrap: nowrap

  will-change: transform

  +d-flex-r(center)

  & > .slide
    +h-padding($spacing-2)
</style>
