export default {
  data() {
    return {
      nextValue: 0,

      hovering: false,
    };
  },
  methods: {
    onHoverStart() {
      this.hovering = true;
    },
    onHover({ target, clientX }) {
      const { left, width } = target.getBoundingClientRect();
      const x = clientX - left;

      this.nextValue = x / width;
    },
    onHoverEnd() {
      this.hovering = false;
    },
  },
};
