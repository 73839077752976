<template>
  <div class="next-frame-button">
    <EButton icon player lg @click="onClick">skip-to-next</EButton>

    <portal to="shaka-player">
      <TooltipOnHover
        v-if="isMounted"
        :target="$el"
        :boundary="getTooltipBoundary()"
      >
        {{ $t('c.player.tooltips.next-frame') }}
      </TooltipOnHover>
    </portal>
  </div>
</template>

<script>
import PlayerControlsButton from './PlayerControlsButton';
import {
  getNextFrameTime,
  pauseSeek,
} from '@/components/the-big-ones/Player/Controls/helpers';

export default {
  name: 'NextFrameButton',
  mixins: [PlayerControlsButton],
  methods: {
    onClick() {
      const { state } = this;

      pauseSeek(state, getNextFrameTime(state));
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
