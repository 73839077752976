<template>
  <div class="player__error">
    <Message
      :title="message.title"
      :message="message.message"
      icon="video-off"
      title-color="white"
      message-color="gray-1"
      icon-color="white"
      slim
    />
  </div>
</template>

<script>
// components
import Message from '@/components/utils/Message/index.vue';

export default {
  components: { Message },
  props: {
    error: { type: Object, required: true },
  },
  computed: {
    isShakaPlayerError() {
      const { error } = this;

      return error && _.isFinite(error.code);
    },

    code() {
      return this.isShakaPlayerError ? this.error.code : undefined;
    },

    message() {
      if (this.isShakaPlayerError) {
        return {
          title: this.$t('c.player.playback-error-title'),
          message: this.$t(`c.player.errors.playback/${this.code}`),
        };
      } else
        return {
          title: this.$t('c.player.playback-error-title'),
          message: this.$t('c.player.errors.default'),
        };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.player__error
  background-color: var(--c-black)

  +d-flex-r(center, center)
  +p-absolute(#{$z-index-base + 1}, 0)
</style>
