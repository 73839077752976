import { render, staticRenderFns } from "./NextFrame.vue?vue&type=template&id=e5ea8ad6&"
import script from "./NextFrame.vue?vue&type=script&lang=js&"
export * from "./NextFrame.vue?vue&type=script&lang=js&"
import style0 from "./NextFrame.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports