import {
  getNextFrameTime,
  getPreviousFrameTime,
  pauseSeek,
  percentageSeek,
  togglePlayPause,
} from '@/components/the-big-ones/Player/Controls/helpers';

const PLAY_PAUSE_HOTKEY = Object.freeze({
  key: new Set([' ', 'k']),
  handler: ({ state }) => togglePlayPause(state),
});

const FULLSCREEN_HOTKEY = Object.freeze({
  key: 'f',
  handler: ({ state }) => state.controls.toggleFullScreen(),
});

const NEXT_FRAME_HOTKEY = Object.freeze({
  key: new Set(['ArrowRight', 'l']),
  handler: ({ state }) => pauseSeek(state, getNextFrameTime(state)),
});

const PREVIOUS_FRAME_HOTKEY = Object.freeze({
  key: new Set(['ArrowLeft', 'j']),
  handler: ({ state }) => pauseSeek(state, getPreviousFrameTime(state)),
});

const PERCENTAGE_HOTKEY = Object.freeze({
  key: new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']),
  handler: ({ state, key }) => percentageSeek(state, 0.1 * Number(key)),
});

export default [
  FULLSCREEN_HOTKEY,
  NEXT_FRAME_HOTKEY,
  PERCENTAGE_HOTKEY,
  PLAY_PAUSE_HOTKEY,
  PREVIOUS_FRAME_HOTKEY,
];
