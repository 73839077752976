<template>
  <div class="you-tube-menu__context">
    <div v-if="title" class="you-tube-menu__context-title">
      <div @click="onTitleClick">
        <icon color="white" class="pr-2">chevron-left</icon>
        <t color="white" sm>{{ title }}</t>
      </div>
    </div>

    <div class="you-tube-menu__context-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YouTubeMenuContext',
  props: {
    title: { type: String, default: '' },

    disabled: { type: Boolean, default: false },
  },
  methods: {
    onTitleClick() {
      if (this.disabled) return;

      this.$emit('stack-pop');
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import ../lib.sass

// base
$border-width: 1px
$min-height: $option-min-height

.you-tube-menu__context-title
  border-bottom-style: solid
  border-bottom-width: $border-width

  padding-bottom: $v-padding

.you-tube-menu__context-title > div
  min-height: $min-height

  +button
  +d-flex-r(center, null, inline)
  +h-padding($spacing-3)

// color
.you-tube-menu__context-title
  border-bottom-color: var(--c-gray-2)
</style>
