import i18n from '@/i18n';

const buildQualityOptions = (currentQuality, qualities, auto) => {
  const options = _.orderBy(
    _.map(qualities, quality => ({
      text: `${quality}p`,
      value: quality,
      active: !auto && currentQuality === quality,
    })),
    'value',
    'desc'
  );
  options.push({ text: 'Auto', value: 'auto', active: auto });

  return options;
};

const buildPlaybackSpeedOptions = currentPlaybackSpeed => {
  return [
    { text: '4x', value: 4, active: currentPlaybackSpeed === 4 },
    { text: '2x', value: 2, active: currentPlaybackSpeed === 2 },
    { text: 'Normal', value: 1, active: currentPlaybackSpeed === 1 },
    { text: '0.5x', value: 0.5, active: currentPlaybackSpeed === 0.5 },
    { text: '0.25x', value: 0.25, active: currentPlaybackSpeed === 0.25 },
  ];
};

export default function(currentPlaybackSpeed, currentQuality, qualities, auto) {
  return [
    {
      key: 'quality',

      type: 'select',
      text: i18n.t('c.player.settings.quality'),

      options: buildQualityOptions(currentQuality, qualities, auto),

      event: 'quality-change',
    },
    {
      key: 'playback-speed',

      type: 'select',
      text: i18n.t('c.player.settings.playback-speed'),

      options: buildPlaybackSpeedOptions(currentPlaybackSpeed),

      event: 'playback-speed-change',
    },
  ];
}
