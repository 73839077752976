<template>
  <div class="you-tube-menu__option" v-bind="$attrs" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'YouTubeMenuOption',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import ../lib.sass

// base
$min-height: $option-min-height

.you-tube-menu__option
  min-height: $min-height

  +button
  +d-flex-r(center)
  +h-padding($spacing-4)

// color
.you-tube-menu__option:hover
  background-color: #2E2F30 // FIX
</style>
