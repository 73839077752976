import { WithIsMounted } from '@/mixins';

// components
import TooltipOnHover from '@/components/ui/Tooltip/TooltipOnHover.vue';

export default {
  components: { TooltipOnHover },
  mixins: [WithIsMounted],
  props: {
    state: { type: Object, required: true },
  },
  methods: {
    getTooltipBoundary() {
      return this.$el.closest('.player');
    },
  },
};
