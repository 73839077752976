var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-projects-show-services-show"},[(_vm.ready)?[(_vm.mq['sm-'] && _vm.services.length > 1)?_c('CollectionCarousel',{attrs:{"type":"services","current-doc-id":_vm.currentDocId,"collection":_vm.services,"minimal":"","wrapped":"","sm":""}}):_vm._e(),(_vm.timeReady && _vm.mobileReady)?_c('TimelapsePlayerDataGrid',{key:_vm.service.id,attrs:{"dimension-id":_vm.dimensionId,"service":_vm.service,"date-range":_vm.dateRange,"manifest":_vm.project.manifest}}):_vm._e(),(_vm.mq['sm-'] && _vm.mobileReady)?[_c('DateRangePickerMobile',{key:"projects-show-services-show",attrs:{"dimension-id":_vm.dimensionId,"min-date":_vm.service.first.timestamp
            ? _vm.$time.moment(_vm.service.first.timestamp).startOf('day')
            : undefined,"max-date":_vm.service.last.timestamp
            ? _vm.$time.moment(_vm.service.last.timestamp).endOf('day')
            : undefined,"default-preset":2}})]:(_vm.mq['md+'] && _vm.desktopReady)?[_c('portal',{attrs:{"to":"header","slim":""}},[_c('DateRangePickerDesktop',{key:"projects-show-services-show",attrs:{"dimension-id":_vm.dimensionId,"min-date":_vm.service.first.timestamp
              ? _vm.$time.moment(_vm.service.first.timestamp).startOf('day')
              : undefined,"max-date":_vm.service.last.timestamp
              ? _vm.$time.moment(_vm.service.last.timestamp).endOf('day')
              : undefined,"default-preset":2}})],1)]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }