import createPopper from '@/components/ui/Tooltip/createPopper';

const OPTIONS = Object.freeze({
  offset: [-10, -5],
  padding: {},
});

export default {
  props: {
    isOpen: { type: Boolean, required: true },
  },
  data() {
    return { popper: null };
  },
  methods: {
    setupPopper() {
      const reference = this.getReference();

      this.popper = createPopper(reference, this.$el, reference, {
        offset: OPTIONS.offset,
        padding: OPTIONS.padding,
        placement: 'bottom-end',
      });
    },
    teardownPopper() {
      this.popper.destroy();
    },
  },
};
