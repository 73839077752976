<template>
  <ShakaPlayer
    v-bind="$attrs"
    class="player"
    v-on="{
      ...$listeners,

      'after-load': onShakaPlayerAfterLoad,
      'before-load': onShakaPlayerBeforeLoad,
      click: onShakaPlayerClick,
      setup: onShakaPlayerSetup,
      timeupdate: onShakaPlayerTimeUpdate,
    }"
  >
    <template v-if="ready">
      <slot name="body" />

      <div class="player__center">
        <slot name="center" />

        <transition name="fade-quickly">
          <PlayerOverlay
            v-if="display.overlay"
            :display-big-play="display.bigPlay"
          >
            <template v-if="display.thumbnail" v-slot:thumbnail>
              <slot name="thumbnail" />
            </template>
          </PlayerOverlay>
        </transition>

        <PlayerSpinner :buffering="state.buffering">
          <slot name="spinner" />

          <PlayerSpinnerMessage v-if="state.loading" key="loading">
            {{ $t('c.player.loading-uri') }}
          </PlayerSpinnerMessage>
        </PlayerSpinner>

        <PlayerFeedback v-if="display.feedback" :playing="state.playing" />
      </div>

      <div class="player__bottom-right">
        <slot name="bottom-right" />
      </div>

      <PlayerError v-if="display.error" :error="_.first(errors)" />
    </template>

    <template v-slot:bottom>
      <PlayerControls v-if="ready" :state="state" />
    </template>
  </ShakaPlayer>
</template>

<script>
import { togglePlayPause } from './Controls/helpers';

import PlayerHotKeys from './HotKeys';
import PlayerState from './State';

// components
import PlayerControls from './Controls/index.vue';
import PlayerError from './Error.vue';
import PlayerFeedback from './Feedback.vue';
import PlayerOverlay from './Overlay.vue';
import PlayerSpinner from './Spinner.vue';
import PlayerSpinnerMessage from './SpinnerMessage.vue';
import ShakaPlayer from '@/components/the-big-ones/ShakaPlayer/index.vue';

export default {
  name: 'Player',
  components: {
    PlayerControls,
    PlayerError,
    PlayerFeedback,
    PlayerOverlay,
    PlayerSpinner,
    PlayerSpinnerMessage,
    ShakaPlayer,
  },
  data() {
    return {
      state: null,
      hotKeys: null,

      errors: [],
    };
  },
  computed: {
    display() {
      const { state } = this;

      return {
        bigPlay: state.canplay,
        controls: this.ready,
        error: this.errors.length > 0,
        feedback: !state.seeking,
        overlay: !state.played,
        thumbnail: !state.played,
      };
    },

    ready() {
      return !_.isNull(this.state) && !_.isNull(this.hotKeys);
    },
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    teardown() {
      if (this.hotKeys) this.hotKeys.teardown();
      if (this.state) this.state.teardown();
    },

    onShakaPlayerSetup({ player, shakaPlayer, media }) {
      this.teardown();

      this.state = new PlayerState(player, shakaPlayer, this.$el);
      this.hotKeys = new PlayerHotKeys(this.state);

      this.$emit('setup', { state: this.state, media });
    },
    onShakaPlayerBeforeLoad() {
      this.errors = [];
    },
    onShakaPlayerAfterLoad({ error }) {
      if (error) this.errors.push(error);
    },
    onShakaPlayerTimeUpdate({ target: player }) {
      if (this.ready) this.$emit('time-change', player.currentTime);
    },
    onShakaPlayerClick() {
      if (this.ready) togglePlayPause(this.state);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass
@import lib.sass

// base
$center-z-index: $z-index-base
$center-padding: $spacing-0

$bottom-right-z-index: #{$center-z-index + 1}
$bottom-right-padding: $spacing-3

.player
  box-shadow: $card-elevation
  overflow: hidden

  &:fullscreen
    +d-flex-c(center, center)

.player__center
  +d-flex-r(center, center)
  +p-absolute($center-z-index, $center-padding)

.player__bottom-right
  +p-absolute-bottom-right($bottom-right-z-index, $bottom-right-padding)

+media-up(md)
  .player
    border-radius: $b-radius-2

// color
.player
  background-color: var(--c-black)
</style>
