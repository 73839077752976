import API from '@/API';
import i18n from '@/i18n';
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';
import { time } from '@/helpers';

class Control {
  static key;

  serviceId;

  display = false;
  label;
  icon;

  value = false;

  fetching = false;
  ready = false;

  constructor(serviceId) {
    this.serviceId = serviceId;
  }
}

class HeatmapControl extends Control {
  static PROCESS = PROCESSES.heatmap;
  static key = 'heatmap';

  label = i18n.t('c.service-player-with-controls.heatmap');
  icon = 'blur-on';

  service;

  async fetchDisplay(dateRange) {
    const { PROCESS } = HeatmapControl;

    for (let day of time.dateRangeDays(dateRange)) {
      day = time.format.asRemoteDate(day);
      const processes = await API.processes(this.serviceId, day, PROCESS);

      if (HeatmapControl.isHeatmapProcesses(processes)) return true;
    }

    return false;
  }

  static isHeatmapProcesses(processes) {
    if (!processes || !processes.periods) return false;

    const periods = _.values(processes.periods);

    return Boolean(
      _.some(periods, period => Boolean(period.heatmap || period.filename))
    );
  }
}

class WithoutDetectionsControl extends Control {
  static PROCESS = PROCESSES.withoutDetections;
  static key = 'withoutDetections';

  label = i18n.t('c.service-player-with-controls.no-detections');
  icon = 'center-focus-weak';

  async fetchDisplay(dateRange) {
    const { PROCESS } = WithoutDetectionsControl;

    for (let day of time.dateRangeDays(dateRange)) {
      day = time.format.asRemoteDate(day);
      const processes = await API.processes(this.serviceId, day, PROCESS);

      if (processes) return true;
    }

    return false;
  }
}

class Controls {
  static CONTROLS = Object.freeze([HeatmapControl, WithoutDetectionsControl]);

  constructor(serviceId) {
    _.forEach(
      Controls.CONTROLS,
      constructor => (this[constructor.key] = new constructor(serviceId))
    );
  }

  fetchDisplay(dateRange) {
    _.forEach(Controls.CONTROLS, async ({ key }) => {
      const control = this[key];

      control.display = await control.fetchDisplay(dateRange);
    });
  }
}

export default Controls;
