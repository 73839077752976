<template>
  <div class="player__settings-button">
    <EButton :disabled="disabled_" icon player lg @click="isOpen = !isOpen">
      settings
    </EButton>

    <portal to="shaka-player">
      <TooltipOnHover
        v-if="isMounted"
        :target="$el"
        :disabled="disabled_ || isOpen"
        :boundary="getTooltipBoundary()"
      >
        {{ $t('c.player.tooltips.settings') }}
      </TooltipOnHover>

      <YouTubeMenu
        :is-open="isOpen"
        :playback-speed="state.playbackSpeed"
        :quality="state.quality"
        :qualities="state.qualities"
        :auto="state.auto"
        @close="isOpen = false"
        @playback-speed-change="onPlaybackSpeedChange"
        @quality-change="onQualityChange"
      />
    </portal>
  </div>
</template>

<script>
import PlayerControlsButton from './PlayerControlsButton';
import {
  setPlaybackSpeed,
  setQuality,
} from '@/components/the-big-ones/Player/Controls/helpers';

// components
import YouTubeMenu from '@/components/ui/YouTubeMenu/index.vue';

export default {
  name: 'SettingsButton',
  components: { YouTubeMenu },
  mixins: [PlayerControlsButton],
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return { isOpen: false };
  },
  computed: {
    disabled_() {
      return this.disabled || !this.ready;
    },

    ready() {
      const { state } = this;

      return (
        _.isFinite(state.playbackSpeed) &&
        _.isFinite(state.quality) &&
        state.qualities.length > 0
      );
    },
  },
  methods: {
    onPlaybackSpeedChange(playbackSpeed) {
      setPlaybackSpeed(this.state, playbackSpeed);
    },
    onQualityChange(quality) {
      setQuality(this.state, quality);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
