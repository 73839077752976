<template>
  <div class="timelapse-player-data-grid">
    <ServicePlayerWithControls
      :dimension-id="dimensionId"
      :service="service"
      :date-range="dateRange"
      :current-time="currentTime"
      @loaded="onLoaded"
    />

    <DataSingle
      v-if="manifest_"
      :project-id="service.id"
      :dimension-id="dimensionId"
      :manifest="manifest_"
      class="mt-3"
    />
  </div>
</template>

<script>
import { WithViewport } from '@/mixins';

// components
import DataSingle from '@/components/the-big-ones/Dashboard/DataSingle.vue';
import ServicePlayerWithControls from '@/components/the-big-ones/ServicePlayer/ServicePlayerWithControls/index.vue';

export default {
  name: 'TimelapsePlayerDataGrid',
  components: { DataSingle, ServicePlayerWithControls },
  mixins: [WithViewport],
  props: {
    dimensionId: { type: String, required: true },
    service: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    manifest: { type: Object, required: true },
  },
  data() {
    const manifest = !_.isEmpty(this.manifest)
      ? this.manifest.services[this.service.id] ||
        this.manifest.services.default
      : undefined;

    return {
      // player
      currentTime: NaN,
      duration: NaN,

      // chart
      manifest_: manifest,
    };
  },
  methods: {
    onLoaded({ currentTime, duration }) {
      this.currentTime = currentTime;
      this.duration = duration;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
+media-up(md)
  .timelapse-player-data-grid > .sub-header
    +v-padding($spacing-3)

+media-down(md)
  .timelapse-player-data-grid > .sub-header
    +v-padding($spacing-2)
    +h-padding($spacing-3)

    & > .e-text
      font-size: $f-size-sm !important

  .timelapse-player-data-grid > .data-single
    +h-padding($spacing-2)

// color
+media-up(md)
  .timelapse-player-data-grid > .sub-header
    background-color: initial
</style>
