<template>
  <div class="service-player-with-controls">
    <ServicePlayerControls
      :service="service"
      :date-range="dateRange"
      :state="state"
      :media="media"
      :controls="controls"
      class="sub-header"
      @input="(value, key) => (controls[key].value = value)"
    >
      <icon class="mr-2">video</icon>
      <t semibold>{{ service.name }}</t>
    </ServicePlayerControls>

    <ServicePlayer
      v-bind="servicePlayerBind"
      @fetching-uri="fetchingURI = $event"
      @setup="onServicePlayerSetup"
      @time-change="onServicePlayerTimeChange"
      v-on="$listeners"
    >
      <template v-slot:body>
        <transition name="fade-normal">
          <img
            v-if="controls.heatmap.value && heatmapURI"
            :src="heatmapURI"
            class="heatmap"
          />
        </transition>
      </template>

      <template v-slot:spinner>
        <PlayerSpinnerMessage v-if="fetchingURI" key="default">
          {{ $t(`${$options.path}.fetching.uri`) }}
        </PlayerSpinnerMessage>

        <PlayerSpinnerMessage v-if="controls.heatmap.fetching" key="heatmap">
          {{ $t(`${$options.path}.fetching.heatmap`) }}
        </PlayerSpinnerMessage>

        <PlayerSpinnerMessage
          v-if="controls.withoutDetections.fetching"
          key="without-detections"
        >
          {{ $t(`${$options.path}.fetching.without-detections-uri`) }}
        </PlayerSpinnerMessage>
      </template>
    </ServicePlayer>
  </div>
</template>

<script>
import { PROCESSES } from '@/components/the-big-ones/ServicePlayer/constants';

import Controls from './controls';
import ServicePlayerBridge from './../ServicePlayerBridge.ts';

// components
import PlayerSpinnerMessage from '@/components/the-big-ones/Player/SpinnerMessage.vue';
import ServicePlayer from '@/components/the-big-ones/ServicePlayer/ServicePlayer.vue';
import ServicePlayerControls from '@/components/the-big-ones/ServicePlayer/ServicePlayerControls.vue';

export default {
  name: 'ServicePlayerWithControls',
  components: { PlayerSpinnerMessage, ServicePlayer, ServicePlayerControls },
  props: {
    service: { type: Object, required: true },
    dateRange: { type: Object, required: true },
  },
  data() {
    return {
      controls: new Controls(this.service.id),

      state: null,
      media: null,

      heatmapURI: '',

      fetchingURI: false,
    };
  },
  computed: {
    process() {
      const { withoutDetections } = this.controls;

      return withoutDetections.value
        ? PROCESSES.withoutDetections
        : PROCESSES.default;
    },

    servicePlayerBind() {
      const bind = { process: this.process };

      return Object.assign(bind, this.$props, this.$attrs);
    },

    ready() {
      return !_.isNull(this.state);
    },
  },
  watch: {
    async 'controls.heatmap.value'() {
      const { heatmap } = this.controls;
      if (!heatmap.value || heatmap.ready) return;

      heatmap.fetching = true;
      try {
        heatmap.service = new ServicePlayerBridge(
          this.service.id,
          this.dateRange
        );
        heatmap.ready = await heatmap.service.setup();
      } finally {
        heatmap.fetching = false;
      }
    },

    dateRange: { handler: 'onDateRangeChange', immediate: true },
  },
  methods: {
    onDateRangeChange() {
      this.controls.fetchDisplay(this.dateRange);
    },

    onServicePlayerSetup({ state, media }) {
      this.state = state;
      this.media = media;
    },

    async onServicePlayerTimeChange(time) {
      const { heatmap } = this.controls;
      if (!heatmap.value || !heatmap.ready) return;
      const response = await heatmap.service.getHeatmapByTime(time);

      this.heatmapURI =
        response && response.heatmap ? response.heatmap.uri : '';
    },
  },

  path: 'c.service-player-with-controls',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.service-player-with-controls > .service-player-controls
  +v-padding($spacing-3)

+media-up(md)
  .service-player-with-controls > .sub-header
    background-color: initial

+media-down(md)
  .service-player-with-controls > .service-player-controls
    +h-padding($spacing-3)

img.heatmap
  +p-absolute-top-left($z-index-base, 0)
  +size(100%)
</style>
