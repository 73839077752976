import shaka from '@/components/the-big-ones/ShakaPlayer/shaka';
import configuration from './configuration';

export class Controls extends shaka.ui.Controls {
  static SHAKA_CONTROLS_SELECTOR = '.shaka-controls-container';
  static SHAKA_SPINNER_SELECTOR = '.shaka-spinner-container';

  constructor(shakaPlayer, playerContainer, player) {
    super(shakaPlayer, playerContainer, player, configuration);

    playerContainer.querySelector(Controls.SHAKA_CONTROLS_SELECTOR).remove();
    playerContainer.querySelector(Controls.SHAKA_SPINNER_SELECTOR).remove();
  }
}

export default Controls;
