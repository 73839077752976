<template>
  <transition name="fade-normal" @enter="setup" @after-leave="teardown">
    <div v-if="isOpen">
      <div
        v-click-outside="() => $emit('close')"
        :style="style"
        :class="className"
      >
        <div ref="background" class="you-tube-menu__background" />

        <div ref="body" :class="bodyClassName">
          <YouTubeMenuComponent
            v-if="animation.direction"
            key="previous-ctx"
            ref="previous"
            :type="previousCtx.type"
            :event="previousCtx.event"
            :options="previousCtx.options"
            :text="previousCtx.text"
            disabled
          />

          <YouTubeMenuComponent
            key="ctx"
            ref="current"
            :type="ctx.type"
            :event="ctx.event"
            :options="ctx.options"
            :text="ctx.text"
            @stack-pop="onStackPop"
            @stack-push="onStackPush"
            v-on="$listeners"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import WithPopper from './WithPopper';
import { tokens } from '@/helpers';

import buildOptions from './buildOptions';

// components
import YouTubeMenuComponent from './YouTubeMenuComponent.vue';

export default {
  name: 'YouTubeMenu',
  components: { YouTubeMenuComponent },
  mixins: [WithPopper],
  props: {
    isOpen: { type: Boolean, required: true },

    playbackSpeed: { type: Number, required: true },

    quality: { type: Number, required: true },
    qualities: { type: Array, required: true },
    auto: { type: Boolean, required: true },
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    options() {
      return buildOptions(
        this.playbackSpeed,
        this.quality,
        this.qualities,
        this.auto
      );
    },

    previousCtx() {
      return this.getCtxByStack(this.previousStack);
    },
    ctx() {
      return this.getCtxByStack(this.stack);
    },

    bodyClassName() {
      const className = 'you-tube-menu__body';
      const { direction } = this.animation.direction;

      return [
        className,
        { [`${className}--${direction}`]: Boolean(direction) },
      ];
    },

    style() {
      return { '--height': `${this.height}px` };
    },
    className() {
      const className = 'you-tube-menu';

      return [className, { [`${className}--mounted`]: this.isMounted }];
    },
  },
  watch: {
    'animation.direction'(direction) {
      if (!direction) return;

      this.$nextTick(() => {
        const { animation } = this;
        if (animation.instance) animation.instance.pause();

        const { body, current, previous } = this.$refs;
        const { width } = previous.$el.getBoundingClientRect();

        this.updateSize(current.$el);

        animation.instance = this.$a({
          translateX: [0, (direction === 'to-right' ? -1 : 1) * width],
          targets: body,
          duration: tokens.durations('normal'),
          easing: 'cubicBezier(0.4, 0.0, 0.2, 1)',

          complete: () => {
            animation.direction = '';
            body.style.transform = 'none';
          },
        });
      });
    },
  },
  updated() {
    this.updateSize();
  },
  methods: {
    getDefaultData() {
      return {
        type: 'tree',

        stack: [],
        previousStack: null,

        height: 0,
        animation: { direction: '', instance: null },

        isMounted: false,
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },

    setup() {
      this.updateSize();

      this.$nextTick(() => {
        this.setupPopper();

        this.isMounted = true;
      });
    },
    teardown() {
      this.teardownPopper();
      this.reset();
    },

    getReference() {
      // FIX

      return this.$el.parentElement.parentElement.querySelector(
        '.shaka-player__body'
      );
    },

    getCtxByStack(stack) {
      return stack.length > 0 ? _.get(this.options, stack) : this;
    },

    onStackPush(index) {
      this.previousStack = _.clone(this.stack);
      this.stack.push(index);

      this.animation.direction = 'to-right';
    },
    onStackPop() {
      this.previousStack = _.clone(this.stack);
      this.stack.pop();

      this.animation.direction = 'to-left';
    },

    updateSize() {
      const { current } = this.$refs;
      if (!current) return;

      this.height = current.$el.getBoundingClientRect().height;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import lib.sass

// base
$easing: cubic-bezier(0.4, 0.0, 0.2, 1)
$width: 256px
$z-index: $z-index-base

.you-tube-menu
  overflow: hidden

  transform: translate(-100%, -100%)

  +p-absolute($z-index)

.you-tube-menu,
.you-tube-menu__background
  width: $width
  height: var(--height)

  box-sizing: content-box

  +v-padding($v-padding)

.you-tube-menu__background
  bottom: 0

  border-radius: $b-radius-2

  +p-absolute(#{$z-index})

.you-tube-menu--mounted
  &, .you-tube-menu__background
    transition: height $duration-normal $easing, width $duration-normal $easing

.you-tube-menu__body
  +d-flex-r(flex-end)
  +p-absolute(#{$z-index + 1}, $v-padding, 0)

  & > *
    flex-shrink: 0

    width: $width

.you-tube-menu__body--to-right,
.you-tube-menu__body--to-left
  pointer-events: none

.you-tube-menu__body--to-right
  flex-direction: row

.you-tube-menu__body--to-left
  flex-direction: row-reverse

// color
.you-tube-menu__background
  background-color: #242424
</style>
