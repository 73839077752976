<template>
  <div class="v-projects-show-services-show">
    <template v-if="ready">
      <CollectionCarousel
        v-if="mq['sm-'] && services.length > 1"
        type="services"
        :current-doc-id="currentDocId"
        :collection="services"
        minimal
        wrapped
        sm
      />

      <TimelapsePlayerDataGrid
        v-if="timeReady && mobileReady"
        :key="service.id"
        :dimension-id="dimensionId"
        :service="service"
        :date-range="dateRange"
        :manifest="project.manifest"
      />

      <!-- mobile -->
      <template v-if="mq['sm-'] && mobileReady">
        <DateRangePickerMobile
          key="projects-show-services-show"
          :dimension-id="dimensionId"
          :min-date="
            service.first.timestamp
              ? $time.moment(service.first.timestamp).startOf('day')
              : undefined
          "
          :max-date="
            service.last.timestamp
              ? $time.moment(service.last.timestamp).endOf('day')
              : undefined
          "
          :default-preset="2"
        />
      </template>

      <!-- desktop -->
      <template v-else-if="mq['md+'] && desktopReady">
        <portal to="header" slim>
          <DateRangePickerDesktop
            key="projects-show-services-show"
            :dimension-id="dimensionId"
            :min-date="
              service.first.timestamp
                ? $time.moment(service.first.timestamp).startOf('day')
                : undefined
            "
            :max-date="
              service.last.timestamp
                ? $time.moment(service.last.timestamp).endOf('day')
                : undefined
            "
            :default-preset="2"
          />
        </portal>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WithProject, WithService, WithTime, WithViewport } from '@/mixins';

// components
import CollectionCarousel from '@/components/collection/CollectionCarousel.vue';
import TimelapsePlayerDataGrid from '@/components/the-big-ones/TimelapsePlayerDataGrid.vue';
// - date range picker
import DateRangePickerDesktop from '@/components/the-big-ones/DateRangePicker/desktop.vue';
import DateRangePickerMobile from '@/components/the-big-ones/DateRangePicker/mobile.vue';

export default {
  name: 'VProjectsShowServicesShow',
  components: {
    CollectionCarousel,
    DateRangePickerDesktop,
    DateRangePickerMobile,
    TimelapsePlayerDataGrid,
  },
  mixins: [WithProject(true), WithService, WithTime, WithViewport],
  computed: {
    ...mapGetters('collections', ['areServicesFetchedByIds']),

    dimensionId() {
      return this.$route.params.projectId;
    },

    currentDocId() {
      const { serviceId } = this.$route.params;

      return serviceId;
    },

    mobileReady() {
      return Boolean(this.service);
    },
    desktopReady() {
      return this.areServicesFetchedByIds(this.project.servicesIds);
    },
    ready() {
      return Boolean(this.project);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$padding-left-desktop: 32px // FIX

.v-projects-show-services-show
  min-height: 100%

+media-down(md)
  .v-projects-show-services-show
    // avoiding top padding because of the header
    padding-bottom: $spacing-3

    +d-flex-c

    & > *:nth-child(1)
      flex-shrink: 0

    & > *:nth-child(2)
      flex: 1

      overflow-y: auto

+media-up(md)
  .v-projects-show-services-show
    padding-right: $spacing-4
    // increasing left padding because for a better content separation
    padding-left: $padding-left-desktop

    +v-padding($spacing-3)

// color
.v-projects-show-services-show
  background-color: var(--c-gray-0)
</style>
