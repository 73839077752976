<template>
  <div class="player__controls">
    <PlayerSeekBar :state="state" />

    <div class="player__controls-body h-spacing-2">
      <PlayButton :state="state" />
      <PreviousFrameButton :state="state" />
      <NextFrameButton :state="state" />
      <PlayerTime :state="state" />

      <div class="spacer" />

      <SettingsButton :state="state" />
      <FullScreenButton :state="state" />
    </div>
  </div>
</template>

<script>
// components
import FullScreenButton from './buttons/FullScreen';
import NextFrameButton from './buttons/NextFrame.vue';
import PlayButton from './buttons/Play.vue';
import PlayerSeekBar from './SeekBar/index.vue';
import PlayerTime from './Time.vue';
import PreviousFrameButton from './buttons/PreviousFrame.vue';
import SettingsButton from './buttons/Settings.vue';

export default {
  name: 'PlayerControls',
  components: {
    FullScreenButton,
    NextFrameButton,
    PlayButton,
    PlayerSeekBar,
    PlayerTime,
    PreviousFrameButton,
    SettingsButton,
  },
  props: {
    state: { type: Object, required: true },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import ../lib.sass

// base
$height: 48px
$z-index: $z-index-base

$seek-bar-height: 32px

.player__controls
  position: relative

.player__controls-body
  z-index: $z-index

  width: 100%
  height: $height

  +d-flex-r(center)
  +h-padding($spacing-2)

.player__controls > .player__seek-bar
  top: -$seek-bar-height

  width: 100%

  +p-absolute(#{$z-index + 1})

// color
.player__controls-body
  background-color: $c-player
</style>
