<template>
  <div class="player__play-button">
    <EButton icon player lg @click="onClick">
      {{ state.playing ? 'pause' : 'play' }}
    </EButton>

    <portal to="shaka-player">
      <TooltipOnHover
        v-if="isMounted"
        :target="$el"
        :boundary="getTooltipBoundary()"
      >
        {{
          isPaused
            ? $t('c.player.tooltips.pause')
            : $t('c.player.tooltips.play')
        }}
      </TooltipOnHover>
    </portal>
  </div>
</template>

<script>
import PlayerControlsButton from './PlayerControlsButton';
import { togglePlayPause } from '@/components/the-big-ones/Player/Controls/helpers';

export default {
  name: 'PlayButton',
  mixins: [PlayerControlsButton],
  computed: {
    isPaused() {
      return this.state.paused || this.state.controls.isSeeking();
    },
  },
  methods: {
    onClick() {
      togglePlayPause(this.state);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
