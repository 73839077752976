<template>
  <div v-if="display" class="player__full-screen-button">
    <EButton icon player lg @click="onClick">
      {{ state.fullScreen ? 'full-screen-off' : 'full-screen' }}
    </EButton>

    <portal to="shaka-player">
      <TooltipOnHover
        v-if="isMounted"
        :target="$el"
        :boundary="getTooltipBoundary()"
      >
        {{
          state.fullScreen
            ? $t('c.player.tooltips.full-screen-off')
            : $t('c.player.tooltips.full-screen-on')
        }}
      </TooltipOnHover>
    </portal>
  </div>
</template>

<script>
import PlayerControlsButton from './PlayerControlsButton';

export default {
  name: 'FullScreenButton',
  mixins: [PlayerControlsButton],
  computed: {
    display() {
      return document.fullscreenEnabled;
    },
  },
  methods: {
    onClick() {
      this.state.controls.toggleFullScreen();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
