<template>
  <t color="white" xs no-wrap><slot /></t>
</template>

<script>
export default {
  name: 'PlayerSpinnerMessage',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
