<template>
  <Context
    :title="text"
    :disabled="disabled"
    class="you-tube-menu__context--tree"
    v-on="$listeners"
  >
    <component
      :is="components[option.type]"
      v-for="(option, index) in options"
      :key="option.key || option.text"
      :option="option"
      @click.native="onOptionClick(index)"
    />
  </Context>
</template>

<script>
// components
import Context from './Context.vue';
import SelectOption from './../options/Select.vue';

export default {
  name: 'YouTubeMenuTreeContext',
  components: { Context, SelectOption },
  props: {
    options: { type: Array, required: true },
    text: { type: String, default: '' },

    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      components: { select: SelectOption },
    };
  },
  methods: {
    onOptionClick(index) {
      if (this.disabled) return;

      this.$emit('stack-push', index);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
