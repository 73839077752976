import Controls from './Controls';

export class PlayerState {
  static PLAYBACK_SPEEDS = [4, 2, 1, 0.5, 0.25];

  // player
  canplay = false;
  pauseseeked = false;
  played = false;
  playing = false;
  seeking = false;
  userplaying = false;

  // shaka
  buffering = false;
  loading = false;

  // document
  fullScreen = document.fullscreenElement;

  currentTime = NaN;
  duration = NaN;
  currentFrame = NaN;
  frames = NaN;
  playbackSpeed_ = NaN;

  activeTrackId = null;
  tracksMetadata = null;
  auto = true;

  player = null;
  shakaPlayer = null;
  controls = null;

  constructor(player, shakaPlayer, playerContainer) {
    this.player = player;
    this.shakaPlayer = shakaPlayer;
    this.controls = new Controls(shakaPlayer, playerContainer, player);

    // binding
    _.bindAll(this, [
      // player
      'onCanPlay',
      'onPause',
      'onPauseSeeked',
      'onPlay',
      'onSeeked',
      'onSeeking',
      'onTimeUpdate',
      'onUserTogglePlayPause',

      // shaka player
      'onABRStatusChanged',
      'onAdaptation',
      'onBuffering',
      'onLoaded',
      'onLoading',
      'onStreaming',
      'onUnloading',

      // document
      'onFullScreenChange',
    ]);

    const playerListeners = {
      canplay: this.onCanPlay,
      pause: this.onPause,
      pauseseeked: this.onPauseSeeked,
      play: this.onPlay,
      seeked: this.onSeeked,
      seeking: this.onSeeking,
      timeupdate: this.onTimeUpdate,
      usertoggleplaypause: this.onUserTogglePlayPause,
    };
    const shakaPlayerListeners = {
      abrstatuschanged: this.onABRStatusChanged,
      adaptation: this.onAdaptation,
      buffering: this.onBuffering,
      loaded: this.onLoaded,
      loading: this.onLoading,
      streaming: this.onStreaming,
      unloading: this.onUnloading,
      variantchanged: this.onAdaptation,
    };
    const documentListeners = {
      fullscreenchange: this.onFullScreenChange,
    };

    this.teardown = () =>
      PlayerState.teardown(
        player,
        playerListeners,
        shakaPlayer,
        shakaPlayerListeners,
        documentListeners
      );

    PlayerState.setup(
      player,
      playerListeners,
      shakaPlayer,
      shakaPlayerListeners,
      documentListeners
    );
  }

  get frameRate() {
    const { activeTrackId, tracksMetadata } = this;
    if (
      !tracksMetadata ||
      _.isNil(activeTrackId) ||
      !_.inRange(activeTrackId, 0, tracksMetadata.length)
    )
      return NaN;

    return tracksMetadata[activeTrackId].frameRate;
  }

  get playbackSpeed() {
    return this.playbackSpeed_;
  }

  set playbackSpeed(playbackSpeed) {
    this.player.playbackRate = playbackSpeed;

    this.playbackSpeed_ = this.player.playbackRate;
  }

  get playbackSpeeds() {
    return PlayerState.PLAYBACK_SPEEDS;
  }

  get quality() {
    const { activeTrackId, tracksMetadata } = this;
    if (
      !tracksMetadata ||
      _.isNil(activeTrackId) ||
      !_.inRange(activeTrackId, 0, tracksMetadata.length)
    )
      return NaN;

    return tracksMetadata[activeTrackId].quality;
  }

  set quality(quality) {
    const { shakaPlayer } = this;

    if (quality === 'auto') shakaPlayer.configure('abr.enabled', true);
    else {
      const track = _.find(shakaPlayer.getVariantTracks(), { height: quality });
      if (!track) return;

      shakaPlayer.configure('abr.enabled', false);
      shakaPlayer.selectVariantTrack(track);
    }
  }

  get qualities() {
    const { tracksMetadata } = this;
    if (!tracksMetadata) return [];

    return _.map(tracksMetadata, 'quality');
  }

  onCanPlay() {
    this.canplay = true;
  }

  onPause() {
    this.playing = false;
  }

  onPauseSeeked() {
    this.pauseseeked = true;
  }

  onPlay() {
    const { player } = this;

    this.pauseseeked = false;
    this.played = true;
    this.playing = true;

    if (this.playbackSpeed !== player.playbackRate) {
      if (_.isNaN(this.playbackSpeed))
        this.playbackSpeed = player.playbackRate || 1;
      else player.playbackRate = this.playbackSpeed;
    }
  }

  onSeeked() {
    this.seeking = false;
  }

  onSeeking() {
    this.seeking = true;
  }

  onTimeUpdate({ target: player }) {
    this.currentTime = player.currentTime;
    this.duration = player.duration;

    this.currentFrame = _.round(player.currentTime * this.frameRate);
    this.frames = _.ceil(player.duration * this.frameRate);
  }

  onUserTogglePlayPause() {
    this.userplaying = !this.player.paused;
  }

  onABRStatusChanged() {
    this.auto = this.shakaPlayer.getConfiguration().abr.enabled;
  }

  onAdaptation({ target }) {
    this.activeTrackId = PlayerState.getShakaPlayerActiveTrackId(target);
  }

  onBuffering({ buffering }) {
    this.buffering = buffering;
  }

  onLoaded() {
    this.loading = false;
  }

  onLoading() {
    this.loading = true;
  }

  onStreaming({ target: shakaPlayer }) {
    this.tracksMetadata = PlayerState.getShakaPlayerTracksMetadata(shakaPlayer);
  }

  onUnloading() {
    this.canplay = false;
    this.pauseseeked = false;
    this.played = false;
    this.playing = false;
    this.seeking = false;
    this.userplaying = false;
  }

  onFullScreenChange() {
    this.fullScreen = Boolean(document.fullscreenElement);
  }

  static setup(
    player,
    playerListeners,
    shakaPlayer,
    shakaPlayerListeners,
    documentListeners
  ) {
    _.forEach(playerListeners, (listener, event) =>
      player.addEventListener(event, listener)
    );

    if (shakaPlayer)
      _.forEach(shakaPlayerListeners, (listener, event) =>
        shakaPlayer.addEventListener(event, listener)
      );

    _.forEach(documentListeners, (listener, event) =>
      document.addEventListener(event, listener)
    );
  }

  static teardown(
    player,
    playerListeners,
    shakaPlayer,
    shakaPlayerListeners,
    documentListeners
  ) {
    _.forEach(playerListeners, (listener, event) =>
      player.removeEventListener(event, listener)
    );

    if (shakaPlayer)
      _.forEach(shakaPlayerListeners, (listener, event) =>
        shakaPlayer.removeEventListener(event, listener)
      );

    _.forEach(documentListeners, (listener, event) =>
      document.removeEventListener(event, listener)
    );
  }

  static getShakaPlayerActiveTrackId(shakaPlayer) {
    return _.find(shakaPlayer.getVariantTracks(), { active: true }).id;
  }

  static getShakaPlayerTracksMetadata(shakaPlayer) {
    return _.map(
      shakaPlayer.getManifest().variants,
      ({ video: { frameRate, height } }) => ({ frameRate, quality: height })
    );
  }
}

export default PlayerState;
