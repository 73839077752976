<template>
  <div class="service-player-download-button">
    <EButton
      :disabled="disabled"
      filled
      icon
      md
      v-bind="$attrs"
      @click="open({ service, dateRange, playerState: state, media })"
      v-on="$listeners"
    >
      square-download
    </EButton>

    <portal to="app">
      <TooltipOnHover v-if="$el" :target="$el" :disabled="disabled">
        {{ $t('c.service-player-with-controls.download') }}
      </TooltipOnHover>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

// components
import TooltipOnHover from '@/components/ui/Tooltip/TooltipOnHover.vue';

export default {
  name: 'ServicePlayerDownloadButton',
  components: { TooltipOnHover },
  props: {
    service: { type: Object, required: true },
    dateRange: { type: Object, required: true },

    state: { type: Object, default: undefined },
    media: { type: String, default: undefined },
  },
  computed: {
    ...mapGetters('download', ['canOpenWith']),

    disabled() {
      const { service, dateRange, state: playerState, media } = this;

      return (
        this.canOpenWith({ service, dateRange, playerState, media }) !== true
      );
    },
  },
  methods: {
    ...mapMutations('download', ['open']),
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
