<template>
  <t v-if="display" block color="white" xs class="player__time">
    {{ state.currentTime | asVideoTime }} /
    {{ state.duration | asVideoTime }}
  </t>
</template>

<script>
import { asVideoTime } from './filters';

export default {
  name: 'PlayerTime',
  filters: { asVideoTime },
  props: {
    state: { type: Object, required: true },
  },
  computed: {
    display() {
      const { state } = this;

      return (
        _.isFinite(state.currentTime) &&
        _.isFinite(state.duration) &&
        state.duration > 0
      );
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
