<template>
  <Option class="you-tube-menu__option--select-option">
    <icon v-if="option.active" :size="20" color="white" class="mr-2">
      check
    </icon>
    <t color="white" xs>{{ option.text }}</t>
  </Option>
</template>

<script>
// components
import Option from './Option.vue';

export default {
  name: 'YouTubeMenuSelectOption',
  components: { Option },
  props: {
    option: { type: Object, required: true },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
