<template>
  <Carousel
    :page="page"
    :pages="collection.length"
    class="collection-carousel"
    @page-change="onPageChange"
  >
    <Slide v-for="doc in collection" :key="doc.id">
      <DocPreviewWrapper
        v-if="wrapped"
        :active="doc.id === currentDocId"
        :minimal="minimal"
      >
        <DocPreview v-bind="docBind(doc)" />
      </DocPreviewWrapper>

      <DocPreview v-else v-bind="docBind(doc)" />
    </Slide>
  </Carousel>
</template>

<script>
import { WithViewport } from '@/mixins';
import mixin from './mixin';

// components
import Carousel from '@/components/ui/Carousel/index.vue';
import DocPreview from '@/components/collection/DocPreview.vue';
import DocPreviewWrapper from '@/components/collection/DocPreviewWrapper.vue';
import Slide from '@/components/ui/Carousel/Slide.vue';

export default {
  name: 'CollectionCarousel',
  components: { Carousel, DocPreview, DocPreviewWrapper, Slide },
  mixins: [WithViewport, mixin],
  props: {
    currentDocId: {
      type: String,
      default: undefined,
    },

    minimal: {
      type: Boolean,
      default: false,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  watch: {
    currentDocId() {
      const page = _.findIndex(this.collection, { id: this.currentDocId });
      if (page === -1 || this.page === page) return;

      this.page = page;
    },
  },
  methods: {
    docBind(doc) {
      const bind = { doc, type: this.type, minimal: this.minimal };

      return Object.assign({}, bind, this.$attrs);
    },

    onPageChange(page) {
      this.page = page;

      const doc = this.collection[page];
      if (!doc) return;

      this.$emit('focus-change', doc);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
