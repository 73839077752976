<template>
  <div data-shaka-player-container class="shaka-player">
    <div :class="playerContainerClassName">
      <video
        ref="player"
        data-shaka-player
        v-bind="{ ...playerBind, ...$attrs }"
        v-on="$listeners"
      />

      <div class="shaka-player__body" @click="$emit('click')">
        <slot />
      </div>
    </div>

    <div class="shaka-player__bottom">
      <slot name="bottom" />
    </div>

    <portal-target name="shaka-player" multiple />
  </div>
</template>

<script>
import shaka from './shaka';
import { functions, isURI } from '@/helpers';

const BUCKET = process.env.VUE_APP_PROCESS_BUCKET || 'visionai-service-data';

export default {
  name: 'ShakaPlayer',
  props: {
    uri: { type: String, required: true },
  },
  data() {
    return {
      shakaPlayer: null,

      playerBind: { autoplay: true, loop: true },
    };
  },
  computed: {
    playerContainerClassName() {
      const className = 'shaka-player__player-container';

      return [className, { [`${className}--can-play`]: true }];
    },
  },
  watch: { uri: 'load' },
  async mounted() {
    await this.setupShakaPlayer();

    this.load();
  },
  async beforeDestroy() {
    await this.shakaPlayer.unload();
    this.shakaPlayer.destroy();
  },
  methods: {
    async onModifyURI(type, request) {
      if (type !== shaka.net.NetworkingEngine.RequestType.SEGMENT) return;

      const [uri] = request.uris;
      const signedURI = await functions.fetchFileSignedURI({
        filename: uri,
        bucket: BUCKET,
      });
      request.uris[0] = signedURI;
    },

    getMedia(support) {
      // we can only handle media "mpd", "m3u8" and "mp4". If none of those
      // media formats are supported, it returns "undefined"

      if (support.manifest.mpd) return 'mpd';
      if (support.manifest.m3u8) return 'm3u8';
      if (support.media['video/mp4']) return 'mp4';
    },

    async setupShakaPlayer() {
      if (!shaka.Player.isBrowserSupported()) return; // FIX: handle

      const support = await shaka.Player.probeSupport();
      const media = this.getMedia(support);
      if (!media) return; // FIX: handle

      const { player } = this.$refs;

      this.shakaPlayer = new shaka.Player(player);
      this.shakaPlayer
        .getNetworkingEngine()
        .registerRequestFilter(this.onModifyURI);

      this.$emit('setup', { player, shakaPlayer: this.shakaPlayer, media });
    },

    async load() {
      const { uri } = this;

      this.$emit('before-load', { uri });

      if (isURI(uri)) {
        let error_;

        try {
          await this.shakaPlayer.unload();
          await this.shakaPlayer.load(uri);
        } catch (error) {
          error_ = error;
        } finally {
          this.$emit('after-load', { uri, error: error_ });
        }
      }
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$z-index: $z-index-base

.shaka-player__player-container
  z-index: $z-index

  +aspect-ratio

  & > video
    +size(100%)

.shaka-player__bottom
  z-index: #{$z-index + 1}

.shaka-player__bottom + *
  +p-relative(#{$z-index + 2})

.shaka-player:fullscreen
  .shaka-player__player-container
    +p-relative

  .shaka-player__bottom
    +p-absolute
    +shell-bottom(0)

// - can play
.shaka-player__player-container--can-play
  +button
</style>
