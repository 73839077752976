export function getNextFrameTime(state) {
  const { currentTime, duration, frameRate } = state;

  let nextFrameTime = currentTime + parseFloat(1 / frameRate);
  if (nextFrameTime > duration) nextFrameTime = duration;

  return nextFrameTime;
}

export function getPreviousFrameTime(state) {
  const { currentTime, frameRate } = state;

  let nextFrameTime = currentTime - parseFloat(1 / frameRate);
  if (nextFrameTime < 0) nextFrameTime = 0;

  return nextFrameTime;
}

export function pauseSeek(state, time) {
  state.player.pause();
  state.player.currentTime = time;

  state.player.dispatchEvent(new Event('pauseseeked'));
}

export function percentageSeek(state, percentage) {
  state.player.currentTime = state.duration * percentage;
}

export function setPlaybackSpeed(state, playbackSpeed) {
  state.playbackSpeed = playbackSpeed;
}

export function setQuality(state, quality) {
  state.quality = quality;
}

export function togglePlayPause(state) {
  state.player.paused ? state.player.play() : state.player.pause();

  state.player.dispatchEvent(new Event('usertoggleplaypause'));
}
