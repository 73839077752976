<template>
  <div class="slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Slide',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
