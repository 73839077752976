<template>
  <div v-show="animating" class="player__feedback">
    <transition
      name="fade-once"
      @before-enter="animating = true"
      @after-enter="animating = false"
    >
      <div v-if="playing" key="play" class="player__feedback-event">
        <icon color="white">play</icon>
      </div>

      <div v-else key="pause" class="player__feedback-event">
        <icon color="white">pause</icon>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PlayerFeedback',
  props: {
    playing: { type: Boolean, required: true },
  },
  data() {
    return { animating: false };
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import lib.sass

// base
$z-index: $feedback-z-index

.player__feedback
  pointer-events: none

  +d-flex-r(center, center)
  +p-absolute($z-index, 0)

.player__feedback-event
  $p: .2

  display: none

  padding: $event-size * $p

  border-radius: $b-radius-circle

  +size($event-size)

  & > .e-icon
    --size: #{$event-size * (1 - $p * 2)}

// color
.player__feedback-event
  background-color: $c-player

// transitions
.fade-once-enter-active
  display: initial

  animation: fade-once $duration-slowly

@keyframes fade-once
  0%
    opacity: .5

    transform: scale(.5)

  100%
    opacity: .0

    transform: scale(1)
</style>
