<template>
  <Context
    :title="text"
    :disabled="disabled"
    class="you-tube-menu__context--select"
    v-on="$listeners"
  >
    <SelectOption
      v-for="(option, index) in options"
      :key="option.key || option.text"
      :option="option"
      @click.native="onOptionClick(index)"
    />
  </Context>
</template>

<script>
// components
import Context from './Context.vue';
import SelectOption from './../options/SelectOption.vue';

export default {
  name: 'YouTubeMenuSelectContext',
  components: { Context, SelectOption },
  props: {
    event: { type: String, required: true },
    options: { type: Array, required: true },
    text: { type: String, default: '' },

    disabled: { type: Boolean, default: false },
  },
  methods: {
    onOptionClick(index) {
      if (this.disabled) return;

      this.$emit(this.event, this.options[index].value);
      this.$emit('stack-pop');
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
