<template>
  <Tooltip v-bind="$attrs" :display="display"><slot /></Tooltip>
</template>

<script>
import Tooltip from './Tooltip';

// components
import Template from './TextTemplate.vue';

export default {
  name: 'TooltipOnHover',
  components: { Tooltip: Template },
  mixins: [Tooltip],
  data() {
    return { hovering: false };
  },
  computed: {
    display() {
      return this.forceDisplay || (!this.disabled && this.hovering);
    },
  },
  mounted() {
    this.target.addEventListener('mouseenter', this.onHoverStart);
    this.target.addEventListener('touchstart', this.onHoverStart);

    this.target.addEventListener('mouseleave', this.onHoverEnd);
    this.target.addEventListener('touchend', this.onHoverEnd);
  },
  beforeDestroy() {
    this.target.removeEventListener('mouseenter', this.onHoverStart);
    this.target.removeEventListener('touchstart', this.onHoverStart);

    this.target.removeEventListener('mouseleave', this.onHoverEnd);
    this.target.removeEventListener('touchend', this.onHoverEnd);
  },
  methods: {
    onHoverStart() {
      this.hovering = true;
    },
    onHoverEnd() {
      this.hovering = false;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
