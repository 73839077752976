<template>
  <component :is="is" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
// components
import YouTubeMenuSelectContext from './contexts/Select.vue';
import YouTubeMenuTreeContext from './contexts/Tree.vue';

export default {
  name: 'YouTubeMenuComponent',
  props: {
    type: { type: String, required: true },
  },
  computed: {
    is() {
      if (this.type === 'select') return YouTubeMenuSelectContext;
      if (this.type === 'tree') return YouTubeMenuTreeContext;

      return undefined;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
