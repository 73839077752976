import shaka from '@/components/the-big-ones/ShakaPlayer/shaka';

export default {
  controlPanelElements: [],
  overflowMenuButtons: [],
  addSeekBar: false,
  addBigPlayButton: false,
  castReceiverAppId: '',
  clearBufferOnQualityChange: true,
  showUnbufferedStart: false,
  seekBarColors: {
    base: 'rgba(255, 255, 255, 0.3)',
    buffered: 'rgba(255, 255, 255, 0.54)',
    played: 'rgb(255, 255, 255)',
    adBreaks: 'rgb(255, 204, 0)',
  },
  volumeBarColors: {
    base: 'rgba(255, 255, 255, 0.54)',
    level: 'rgb(255, 255, 255)',
  },
  trackLabelFormat: shaka.ui.TrackLabelFormat.LANGUAGE,
  fadeDelay: 0,
  doubleClickForFullscreen: false,
  enableKeyboardPlaybackControls: true,
  enableFullscreenOnRotation: true,
};
