<template>
  <div v-if="display" class="player__seek-bar">
    <PlayerRange
      :value="value"
      :next-value="nextValue"
      hide-thumb
      @change="onRangeChange"
      @input="onRangeInput"
      @mouseenter="onHoverStart"
      @mousemove="onHover"
      @mouseleave="onHoverEnd"
      @mousedown="onSeekingStart"
      @touchstart="onSeekingStart"
      @mouseup="onSeekingEnd"
      @touchend="onSeekingEnd"
    >
      <template v-slot:value-label>
        <transition name="fade-normal" appear mode="out-in">
          <div v-if="state.pauseseeked" class="player__seek-bar__label">
            <t color="white" xs center no-wrap>
              {{ state.currentFrame }} / {{ state.frames }}
            </t>
          </div>
        </transition>
      </template>

      <template v-slot:next-value-label>
        <div class="player__seek-bar__label">
          <t color="white" xs center no-wrap>
            <template v-if="state.pauseseeked">
              {{ nextCurrentFrame }}
            </template>

            <template v-else>
              {{ nextCurrentTime | asVideoTime }}
            </template>
          </t>
        </div>
      </template>
    </PlayerRange>

    <PlayerRange
      :value="value"
      :input-style="{ background: rangeBackground }"
      disabled
      class="player__seek-bar__dummy-range"
    >
    </PlayerRange>
  </div>
</template>

<script>
import RangeHovering from './RangeHovering';
import { asVideoTime } from '@/components/the-big-ones/Player/Controls/filters';

// components
import PlayerRange from './Range.vue';

export default {
  name: 'PlayerSeekBar',
  components: { PlayerRange },
  filters: { asVideoTime },
  mixins: [RangeHovering],
  props: {
    state: { type: Object, required: true },
  },
  data() {
    return {
      value: 0,

      bufferStartFraction: 0,
      bufferEndFraction: 0,

      seeking: false,
    };
  },
  computed: {
    nextCurrentTime() {
      return this.state.duration * this.nextValue;
    },
    nextCurrentFrame() {
      const { state } = this;

      return _.round(this.nextCurrentTime * state.frameRate);
    },

    rangeBackground() {
      let { nextValue } = this;
      let nwBaseFraction, nwBufferedFraction;

      if (!this.hovering) nextValue = 0;

      if (this.bufferEndFraction < nextValue) {
        nwBaseFraction = nextValue;
        nwBufferedFraction = this.bufferEndFraction;
      } else {
        nwBaseFraction = this.bufferEndFraction;
        nwBufferedFraction = nextValue;
      }

      return this.buildDiscreteGradient([
        ['var(--c-unbuffered)', this.bufferStartFraction],
        ['var(--c-played)', this.value],
        ['var(--c-next-with-buffered)', nwBufferedFraction],
        ['var(--c-next-with-base)', nwBaseFraction],
        ['var(--c-base)', null],
      ]);
    },

    display() {
      const { currentTime, duration } = this.state;

      return _.isFinite(currentTime) && _.isFinite(duration) && duration > 0;
    },
  },
  watch: { 'state.currentTime': 'onCurrentTimeChange' },
  methods: {
    buildColor(color, fract) {
      return `${color} ${fract * 100}%`;
    },
    buildDiscreteGradient(colors) {
      return `linear-gradient(${_.reduce(
        colors,
        (gradient, [color, fraction], index) => {
          if (index === 0) gradient += ',' + this.buildColor(color, fraction);
          else {
            const previousFraction = colors[index - 1][1];

            if (index === colors.length)
              gradient += ',' + this.buildColor(color, previousFraction);
            else {
              gradient += ',' + this.buildColor(color, previousFraction);
              gradient += ',' + this.buildColor(color, fraction);
            }
          }

          return gradient;
        },
        'to right'
      )})`;
    },

    onRangeInput({ target: { value } }) {
      this.value = Number(value);
    },
    onRangeChange({ target: { value } }) {
      const { player } = this.state;

      this.value = Number(value);

      player.currentTime = this.state.duration * this.value;
    },

    onCurrentTimeChange() {
      const { player } = this.state;

      if (!this.seeking)
        this.value = this.state.currentTime / this.state.duration;

      const bufferedLength = player.buffered.length;
      const bufferedStart = bufferedLength ? player.buffered.start(0) : 0;
      const bufferedEnd = bufferedLength
        ? player.buffered.end(bufferedLength - 1)
        : 0;

      const seekRange = this.state.shakaPlayer.seekRange();
      const seekRangeSize = seekRange.end - seekRange.start;

      const clampedBufferStart = Math.max(bufferedStart, seekRange.start);
      const clampedBufferEnd = Math.min(bufferedEnd, seekRange.end);

      const bufferStartDistance = clampedBufferStart - seekRange.start;
      const bufferEndDistance = clampedBufferEnd - seekRange.start;

      this.bufferStartFraction = bufferStartDistance / seekRangeSize || 0;
      this.bufferEndFraction = bufferEndDistance / seekRangeSize || 0;
    },

    onSeekingStart() {
      this.seeking = true;

      this.state.player.pause();
    },
    onSeekingEnd() {
      this.seeking = false;

      if (this.state.userplaying) this.state.player.play();
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$thumb-size: 12px
$track-height: 4px

$height: max($track-height, $thumb-size) * 2

.player__seek-bar
  position: relative

  .player__seek-bar__dummy-range
    & > .range__input,
    & > .range__thumb
      transition: transform $duration-quickly

  .player__seek-bar__dummy-range
    & > .range__thumb
      transform: translate(-50%, -50%) scale(0)

  &:hover
    .player__seek-bar__dummy-range
      & > .range__input
        transform: scaleY(1.5)

      & > .range__thumb
        transform: translate(-50%, -50%) scale(1)

.player__seek-bar__dummy-range
  --height: #{$track-height}
  --thumb-size: #{$thumb-size}
  --track-height: #{$track-height}

  +p-absolute
  +shell-bottom(0)

.player__seek-bar__label
  padding: $spacing-1 / 1.5 $spacing-2 / 1.5

  border-radius: $b-radius-1

  +d-flex-r(center, center)

// color
.player__seek-bar
  --c-base: #{rgba(#FFFFFF, .2)}

  --c-unbuffered: #{rgba(#FFFFFF, .2)}
  --c-played: var(--c-primary)
  --c-buffered: #91939B
  --c-next-with-base: #9B9A9B
  --c-next-with-buffered: #C8C9CD

.player__seek-bar__label
  background-color: var(--c-secondary)
</style>
